import { LEAGUE_STAT_MAPPING, DEFAULT_LINE_FORMAT, AVAILABLE_LINE_FORMATS,
         ACCESS_LEVEL_SUPERUSER, ACCESS_LEVEL_FREE, ACCESS_LEVEL_TRIAL, ACCESS_LEVEL_PAID,
         FIELD_DECIMAL_PRECISION,
         PLAYER_BET_TYPE_ID_TO_NAME,
         getPlayerPropDefensiveTeamStatPath, 
         DEFAULT_MARKET_TEXT,
         DEFAULT_HIT_RECORD_ENTRY,
         SOCCER_LEAGUES,
         ALL_GAMES_FILTER_OPTION,
         ALL_BETS_MARKET_NAME,
         ALL_TRENDS_OPTION,
         RECENT_FORM_TREND_OPTION,
         HEAD_TO_HEAD_TREND_OPTION,
         HOME_AWAY_SPLIT_TREND_OPTION,
         OPPONENT_RANK_TREND_OPTION,
         PLAYER_BET_TYPES_REVERSE_MAPPING,
         SCREENER_ONLY_PROPS,
         TEAM_BET_TYPE_REVERSE_MAPPING,
         OVER_UNDER_FILTER,
         BET_TYPE_FILTER,
         TRENDS_FILTER,
         GAMES_FILTER,
         SCHEDULE_FORMAT_WEEKLY,
         FILTER_QUERY_PARAMETER_LOCATION_VALUE,
         FILTER_QUERY_PARAMETER_LOCATION_KEY,
         SUPPORTED_LEAGUES,
         SUPPORTED_LEAGUES_METADATA,
         PARLAY_LEGS_COUNT_FILTER,
         PARLAY_LEGS_TYPE_FILTER,
         ALL_LEG_COUNTS_OPTION} from './constants.js';
import * as CoreUtils from './core-utils.js';
import { Buffer } from 'buffer';

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// sleep time expects milliseconds
export function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export function formatDate(date) {
  const parsedDate = parseDate(date);
  const day = String(parsedDate.day).padStart(2, '0');
  const month = String(parsedDate.month).padStart(2, '0');
  const year = parsedDate.year;
  const formattedDate = `${year}-${month}-${day}-${parsedDate.formattedTime}`;
  return formattedDate;
}

export function parseDate(date) {
  const hours = ((date.getHours() + 11) % 12 + 1);
  const timePeriod = date.getHours() >= 12 ? "PM":"AM";
  const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return {
    date: date,
    seconds: date.getSeconds(),
    minutes: date.getMinutes(),
    hour: hours,
    day: date.getDate(),
    month: date.getMonth() + 1, //January is 0!
    year: date.getFullYear(),
    yearShort: date.getFullYear() % 100,
    timePeriod: timePeriod,
    formattedTime: hours + ':' + String(date.getMinutes()).padStart(2, '0') + " " + timePeriod,
    formattedDayMonthShort: date.toLocaleString('defaut', {month: 'short'}) + " " + date.getDate(),
    formattedDayMonthLong: date.toLocaleString('defaut', {month: 'long'}) + " " + date.getDate(),
    formattedMonthShort: date.toLocaleString('defaut', {month: 'short'}),
    formattedMonthLong: date.toLocaleString('defaut', {month: 'long'}),
    dayOfWeekShort: daysShort[date.getDay()],
    dayOfWeekLong: daysLong[date.getDay()]
  }
}

export function parseDateFromString(dateString) {
  const date = new Date(dateString);
  return parseDate(date)
  // const hours = ((date.getHours() + 11) % 12 + 1);
  // const timePeriod = date.getHours() >= 12 ? "PM":"AM";
  // const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  // const daysShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  // return {
  //   date: date,
  //   seconds: date.getSeconds(),
  //   minutes: date.getMinutes(),
  //   hour: hours,
  //   day: date.getDate(),
  //   month: date.getMonth() + 1, //January is 0!
  //   year: date.getFullYear(),
  //   timePeriod: timePeriod,
  //   formattedTime: hours + ':' + String(date.getMinutes()).padStart(2, '0') + " " + timePeriod,
  //   formattedDayMonthShort: date.toLocaleString('defaut', {month: 'short'}) + " " + date.getDate(),
  //   formattedDayMonthLong: date.toLocaleString('defaut', {month: 'long'}) + " " + date.getDate(),
  //   formattedMonthShort: date.toLocaleString('defaut', {month: 'short'}),
  //   formattedMonthLong: date.toLocaleString('defaut', {month: 'long'}),
  //   dayOfWeekShort: daysShort[date.getDay()],
  //   dayOfWeekLong: daysLong[date.getDay()]
  // }
}

// Input is list of team objects from the API's /teams/ endpoint
export function buildTeamsMap(teamList) {
  var result = {};
  for (var team of teamList) {
    result[team.code] = team;
  }
  return result;
}

// Uses the team object from the API's /teams/ endpoint
export function getTeamRecord(team, league) {
  switch (league) {
    case "nhl":
      return team.standings.wins + "-" + team.standings.losses + "-" + team.standings.overtimeLosses;
    case "nfl":
      return team.standings.wins + "-" + team.standings.losses + "-" + team.standings.ties;
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return team.standings.wins + "-" + team.standings.losses + "-" + team.standings.draws;
    case "ncaaf":
    default:
      return team.standings.wins + "-" + team.standings.losses;
  }
}

function toFixed(num, fixed) {
  var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
  return num.toString().match(re)[0];
}

// Will use a fixed number of decimal digits precision only if the original number is a decimal point number
// Integers will remain whole
export function decimalPrecision(number, decimalPlaces) {
  if (typeof number !== 'number') {
    return number;
  }
  if (number % 1 === 0) {
    //Integer
    return number;
  } else {
    //Floating point
    return number.toFixed(decimalPlaces);
    // return toFixed(number, decimalPlaces);
  }
}

export function decimalPrecisionWithPadding(number, decimalPlaces, keepTrailingZeros, keepLeadingZero) {
  if (number % 1 === 0) {
    //Integer, return as-is
    return number;
  }
  var result = decimalPrecision(number, decimalPlaces);
  if (!keepLeadingZero && result < 1) {
    result = result.toString().substr(1);
  }
  if (keepTrailingZeros) {
    result = result.toString().padEnd(3, "0");
  }
  return result;
}

export function getDictionaryValue(dict, key) {
  const { result, finalChunk } = getDictionaryValueRaw(dict, key);

  // It's not a number type but it could be cast as one, such as a string containing a number
  // Need to make an exception for things prefixed with a '+' for american odds to be kept as-is in string format for display purpose
  if (result != null && typeof result !== 'number' && isNum(result) && !result.startsWith("+")) {
    return Number(result);
  }

  // If the output is not a number don't bother with checking the precision.
  //  We have a check for that under decimalPrecision but that one can be used outside of here
  if (typeof result !== 'number') {
    return result;
  }

  // Default to to 1 decimal point, this means anything that needs 1 decimal point doesn't need to be defined
  var precision = 1;
  if (finalChunk in FIELD_DECIMAL_PRECISION) {
    precision = FIELD_DECIMAL_PRECISION[finalChunk];
  }

  if (precision > 1) {
    // By default we'll say that if we want more precision points than 1 we should pad it up to that amount
    // For now will keep the leading zero, TBD if we want to remove it
    return decimalPrecisionWithPadding(result, precision, true, true);
  } else {
    return decimalPrecision(result, precision);
  }
}

export function  getDictionaryValueRaw(dict, key) {
  return CoreUtils.getDictionaryValueRaw(dict, key)
}

export function getDictionaryValueRawResult(dict, key) {
  return CoreUtils.getDictionaryValueRawResult(dict, key);
}

export function base64Encode(data) {
  return Buffer.from(data).toString("base64");
}

export function buildQueryParams(dict) {
  return Object.keys(dict).map(
    function(key) {
      return key + "=" + encodeURIComponent(dict[key]);
    }
  ).join("&")
  //return Buffer.from(JSON.stringify(dict)).toString("base64");
}

// Sorts a list of object by the value of one of the members
// list: the original list of objects to sort
// field: the field name within the object where the data is to be compared
//        this can be a composite field which 'getDictionaryValue' can find
// order: asc/desc string value to indicate the order
export function orderListByField(list, field, order) {
  //console.log(`Ordering list: {list: ${list}, field: ${field}, order: ${order}}`);
  list.sort(
    function (a, b) {
      if (order.toLowerCase() === "asc") {
        return getDictionaryValue(a, field) - getDictionaryValue(b, field);
      }
      if (order.toLowerCase() === "desc") {
        return getDictionaryValue(b, field) - getDictionaryValue(a, field);
      }
    }
  );
  return list;
}

export function isDictEmpty(dict) {
  return dict === null || typeof dict === 'undefined' || Object.keys(dict).length === 0;
}


export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


export function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (!(code > 47 && code < 58) && // numeric (0-9)
        !(code > 64 && code < 91) && // upper alpha (A-Z)
        !(code > 96 && code < 123)) { // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

export function containsUppercase(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    // upper alpha (A-Z))
    if (code > 64 && code < 91) {
      return true;
    }
  }
  return false;
}

export function containsLowercase(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    // upper alpha (A-Z))
    if (code > 96 && code < 123) {
      return true;
    }
  }
  return false;
}

export function containsSpecialCharacter(str) {
  return /(.*[!@#$%^&*].*)/.test(str);
}

export function containsNumber(str) {
  return /\d/.test(str);
}

// Credit to Jon Kantner @ https://css-tricks.com/converting-color-spaces-in-javascript/
export function hexToHSL(H) {
  // Convert hex to RGB first
  let r = 0, g = 0, b = 0;
  if (H.length === 4) {
    r = "0x" + H[1] + H[1];
    g = "0x" + H[2] + H[2];
    b = "0x" + H[3] + H[3];
  } else if (H.length === 7) {
    r = "0x" + H[1] + H[2];
    g = "0x" + H[3] + H[4];
    b = "0x" + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r,g,b),
      cmax = Math.max(r,g,b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;

  if (delta === 0)
    h = 0;
  else if (cmax === r)
    h = ((g - b) / delta) % 6;
  else if (cmax === g)
    h = (b - r) / delta + 2;
  else
    h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0)
    h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  // return "hsl(" + h + "," + s + "%," + l + "%)";
  return {
    h: h,
    s: s,
    l: l
  }
}

export function fadeHSLColor(h, s, l) {
  return {
    h: h,
    s: (s * 0.45),
    l: (l * 1.40)
  }
}

//https://stackoverflow.com/a/44134328
export function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function buildOpponentList(currentTeam, allTeams) {
  return allTeams.filter((value) => {return value !== currentTeam});
}

//https://stackoverflow.com/a/13627586
export function addOrdinalNumberSuffix(number) {
  var j = number % 10,
  k = number % 100;
  if (j == 1 && k != 11) {
    return number + "st";
  }
  if (j == 2 && k != 12) {
    return number + "nd";
  }
  if (j == 3 && k != 13) {
    return number + "rd";
  }
  return number + "th";
}

// Assumes a dictionary with the standard linemate Game response object as input as well as the abbreviated team code
export function isHomeGame(game, teamCode) {
  if (game.homeTeamCode === teamCode.toUpperCase()) {
    return true;
  }
  return false;
}

export function getOpposingTeamCode(game, teamCode) {
  if (game.homeTeamCode === teamCode.toUpperCase()) {
    return game.awayTeamCode;
  }
  return game.homeTeamCode;
}

export function stringPresent(str) {
  return str !== null && str !== "";
}

export function mergeSubDictionaries(dict) {
  var mergedDict = {};
  Object.keys(dict).map((key) => {
    mergedDict = Object.assign(mergedDict, dict[key]);
  });
  return mergedDict;
}

export function shouldBlockUserAccess(userAttributes) {
  // return isDictEmpty(userAttributes) || userAttributes.accessLevel === 1;
  // return isDictEmpty(userAttributes);
  return false;
}

export function isGuest(userAttributes) {
  return userAttributes === null || isDictEmpty(userAttributes);
}

export function isFreeUser(userAttributes) {
  return !isGuest(userAttributes) && userAttributes.accessLevel === ACCESS_LEVEL_FREE;
}

export function isTrialUser(userAttributes) {
  return !isGuest(userAttributes) && userAttributes.accessLevel === ACCESS_LEVEL_TRIAL;
}

export function isPaidUser(userAttributes) {
  return !isGuest(userAttributes) && userAttributes.accessLevel === ACCESS_LEVEL_PAID;
}

export function isSuperUser(userAttributes) {
  return !isGuest(userAttributes) && userAttributes.accessLevel === ACCESS_LEVEL_SUPERUSER;
}

export function hasPremiumAccess(userAttributes) {
  return isTrialUser(userAttributes) || isPaidUser(userAttributes) || isSuperUser(userAttributes);
}

export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

export function isFieldEmpty(value) {
  return value === null || value === "" || value === "undefined";
}

export function redirectEvent(event) {
  redirect(event.currentTarget.dataset.endpoint);
}

export function redirect(endpoint) {
  window.location.href = endpoint;
}

export function isNum(value) {
  return !isNaN(value);
}

export function getPlayerLeaderStats(league, player) {
  switch(league) {
    case "nba":
    case "wnba":
      return {
        "PTS": "cumulativeStats.points",
        "FTM": "cumulativeStats.freeThrowsMade",
        "FGM": "cumulativeStats.fieldGoalsMade",
        "3PTM": "cumulativeStats.threePointsMade",
        "REB": "cumulativeStats.rebounds",
        "AST": "cumulativeStats.assists"
      }
    case "nfl":
    case "ncaaf":
      return {
        "QB": {
          "COMP": "cumulativeStats.passingCompletions",
          "YDS": "cumulativeStats.passingYards",
          "TD": "cumulativeStats.passingTouchdowns",
          // TODO: Need to figure out what to do with INT since a smaller value is good in terms of the leader
          "INT": "cumulativeStats.interceptions",
          "RZ ATT": "cumulativeStats.redzonePassingAttempts",
          "RZ TD": "cumulativeStats.redzonePassingTouchdowns"
        },
        "RB": {
          "ATT": "cumulativeStats.rushingAttempts",
          "YDS": "cumulativeStats.rushingYards",
          "TD": "cumulativeStats.rushingTouchdowns",
          "TGT": "cumulativeStats.targets",
          "RZ ATT": "cumulativeStats.redzoneRushingAttempts",
          "RZ TD": "cumulativeStats.redzoneRushingTouchdowns"
        },
        "WR": {
          "TGT": "cumulativeStats.targets",
          "REC": "cumulativeStats.receptions",
          "YDS": "cumulativeStats.receivingYards",
          "TD": "cumulativeStats.receivingTouchdowns",
          "RZ TGT": "cumulativeStats.redzoneTargets",
          "RZ TD": "cumulativeStats.redzoneReceivingTouchdowns"
        },
        "TE": {
          "TGT": "cumulativeStats.targets",
          "REC": "cumulativeStats.receptions",
          "YDS": "cumulativeStats.receivingYards",
          "TD": "cumulativeStats.receivingTouchdowns",
          "RZ TGT": "cumulativeStats.redzoneTargets",
          "RZ TD": "cumulativeStats.redzoneReceivingTouchdowns"
        },
        "K": {
          "FGA": "cumulativeStats.fieldGoalAttempts",
          "FGM": "cumulativeStats.fieldGoalsMade",
          "FG%": "cumulativeStats.fieldGoalPercentage",
          "XPA": "cumulativeStats.extraPointAttempts",
          "XPM": "cumulativeStats.extraPointsMade",
          "XP%": "cumulativeStats.extraPointPercentage"
        }
      }[player.info.position.toUpperCase()]
    case "nhl":
      if (player.info.position.toUpperCase() === "G") {
        return {
          "WINS": "cumulativeStats.wins",
          "SV%": "cumulativeStats.savePercentage",
          "GAA": "averageStats.goalsAllowed",
          "SHUTOUTS": "cumulativeStats.shutouts",
          "SA/G": "averageStats.shotsAgainst",
          "SV/G": "averageStats.shotsSaved"
        }
      } else {
        return {
          "PTS": "cumulativeStats.points",
          "G": "cumulativeStats.goals",
          "A": "cumulativeStats.assists",
          "SOG": "cumulativeStats.shotsOnGoal",
          "SAT": "cumulativeStats.shotAttempts",
          "PPP": "cumulativeStats.powerplayPoints"
        }
      }
    case "mlb":
      if (player.info.position.toUpperCase() == "SP" || player.info.position.toUpperCase() == "RP") {
        return {
          "W": "cumulativeStats.pitching.wins",
          "L": "cumulativeStats.pitching.losses",
          "SV": "cumulativeStats.pitching.saves",
          "ERA": "cumulativeStats.pitching.earnedRunsAverage",
          "SO": "cumulativeStats.pitching.strikeouts",
          "WHIP": "cumulativeStats.pitching.walksAndHitsPerInningPitched"
        }
      } else {
        return {
          "H": "cumulativeStats.hitting.hits",
          "R": "cumulativeStats.hitting.runs",
          "RBI": "cumulativeStats.hitting.runsBattedIn",
          "HR": "cumulativeStats.hitting.homeRuns",
          "AVG": "cumulativeStats.hitting.battingAverage",
          "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
        }
      }
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      if (player.info.position.toLowerCase() === "goalkeeper") {
        return {
          "GA": "cumulativeStats.goalsConceded",
          "SAV": "cumulativeStats.shotsFacedSaved",
          "SA": "cumulativeStats.shotsFacedTotal",
          "PS": "cumulativeStats.penaltiesSaved",
          "DSAV": "cumulativeStats.divingSaves",
          "CS": "cumulativeStats.cleanSheet"
        }
      } else {
        return {
          "G": "cumulativeStats.goals",
          "A": "cumulativeStats.assists",
          "SHOT": "cumulativeStats.shotsOnTarget",
          "SHO": "cumulativeStats.totalShots",
          // "CC": "cumulativeStats.chancesCreated",
          "MIN": "cumulativeStats.minutesPlayed"
        }
      }
    default:
      return {}
  }
}

// TODO: for NHL and NFL we could get the leaders for all positions/positionGroups in one API call and split them up after on the client side
// Alternatively, we could fetch the per-position or per-position group leaders on demand if this is too slow
export function fetchPlayerLeaders(host, league, inputState, setStateFn) {
  const API_HOST = host;
  switch(league) {
    case "nba":
    case "wnba":
      fetch(API_HOST + '/api/' + league + '/v1/players/leaders')
      .then(data => data.json())
      .then(data => {
        inputState["playerLeaders"] = {'ALL': data};
        setStateFn(inputState);
      })
      break;
    case "nfl":
    case "ncaaf":
      var promises = [];
      ["QB", "RB", "WR", "TE", "K"].forEach((position) => {
        promises.push(fetch(`${API_HOST}/api/${league}/v1/players/leaders?position=${position}`).then(data => data.json()));
      });
      Promise.all(promises)
      .then(result => {
        var leaders = {};
        ["QB", "RB", "WR", "TE", "K"].forEach((position, index) => {
          leaders[position] = result[index];
        });
        inputState["playerLeaders"] = leaders;
        setStateFn(inputState);
      })
      break;
    case "nhl":
      var promises = [];
      ["SKATERS", "FORWARDS", "DEFENSEMEN", "GOALIES"].forEach((positionGroup) => {
        promises.push(fetch(`${API_HOST}/api/${league}/v1/players/leaders?positionGroup=${positionGroup}`).then(data => data.json()));
      });
      Promise.all(promises)
      .then(result => {
        var leaders = {};
        ["SKATERS", "FORWARDS", "DEFENSEMEN", "GOALIES"].forEach((positionGroup, index) => {
          leaders[positionGroup] = result[index];
        });
        inputState["playerLeaders"] = leaders;
        setStateFn(inputState);
      })
      break;
    case "mlb":
      var promises = [];
      ["PITCHING", "HITTING"].forEach((positionGroup) => {
        promises.push(fetch(`${API_HOST}/api/${league}/v1/players/leaders?positionGroup=${positionGroup}`).then(data => data.json()));
      });
      Promise.all(promises)
      .then(result => {
        var leaders = {};
        ["PITCHING", "HITTING"].forEach((positionGroup, index) => {
          leaders[positionGroup] = result[index];
        });
        inputState["playerLeaders"] = leaders;
        setStateFn(inputState);
      })
      break;
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      var promises = [];
      ["PLAYERS", "GOALIES"].forEach((positionGroup) => {
        promises.push(fetch(`${API_HOST}/api/${league}/v1/players/leaders?positionGroup=${positionGroup}`).then(data => data.json()));
      });
      Promise.all(promises)
      .then(result => {
        var leaders = {};
        ["PLAYERS", "GOALIES"].forEach((positionGroup, index) => {
          leaders[positionGroup] = result[index];
        });
        inputState["playerLeaders"] = leaders;
        setStateFn(inputState);
      })
      break;
    default:
      break;
  }
}

export function getLeaguePlayerLeaders(leaders, league, player) {
  switch(league) {
    case "nfl":
    case "ncaaf":
      return leaders[player.info.position.toUpperCase()];
    case "nhl":
      var positionGroup = "";
      if (["C", "LW", "RW"].includes(player.info.position.toUpperCase())) {
        positionGroup = "FORWARDS";
      }
      if (player.info.position.toUpperCase() === "D") {
        positionGroup = "DEFENSEMEN";
      }
      if (player.info.position.toUpperCase() === "G") {
        positionGroup = "GOALIES";
      }
      return leaders[positionGroup];
    case "nba":
    case "wnba":
      return leaders["ALL"];
    case "mlb":
      if (player.info.position.toUpperCase() == "SP" || player.info.position.toUpperCase() == "RP") {
        return leaders["PITCHING"];
      } else {
        return leaders["HITTING"];
      }
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      if (player.info.position.toLowerCase() === "goalkeepr") {
        return leaders["GOALIES"];
      }
      return leaders["PLAYERS"];
    default:
      return {};
  }
}

export function getPlayerBirthplace(player) {
  if (player.info.birthPlace !== null) {
    return player.info.birthPlace;
  }
  if (player.info.birthCity === null && player.info.birthCountry) {
    return "";
  }
  if (player.info.birthCity !== null) {
    return player.info.birthCity;
  }
  if (player.info.birthCountry !== null) {
    return player.info.birthCountry;
  }
}

// TODO: use the new preferredOddsFormat
export function getPreferredFormat(userAttributes) {
  var format = DEFAULT_LINE_FORMAT;
  if (userAttributes && userAttributes.preferredLineFormat && AVAILABLE_LINE_FORMATS.includes(userAttributes.preferredLineFormat.toLowerCase())) {
    format = userAttributes.preferredLineFormat.toLowerCase();
  }
  return format;
}

// Mostly used for game odds which is a dict
export function getFormattedOdds(userAttributes, odds) {
  const format = getPreferredFormat(userAttributes);
  return Object.assign({format: format}, odds[format]);
}

// Mostly used for player props which is a single value
export function getFormattedOddValue(userAttributes, odds) {
  const format = getPreferredFormat(userAttributes);
  return odds[format];
}

// Replacement of the above 'getFormattedOddValue' as we try to deprecate it
// If formatted=true it will format the odds to be display-ready. Positive american odds will have the '+' sign, decimal odds will have the decimal fixed to 2 placed, etc.
// If formatted=false it will return them raw as-is from the back-end
export function getPreferredOdds(userAttributes, odds, formatted) {
  if (!odds) {
    return null;
  }
  const format = getPreferredFormat(userAttributes);
  if (formatted) {
    return formatOdds(format, odds[format])
  }
  return odds[format];
}

// Technically incorrect to call this the line value since it's the odds, not the line, but we'll leave it for now until we find a better name
// 'getFormattedOddValue' is already used so we would have to re-name that one first
export function getFormattedLineValue(format, value) {
  switch(format) {
    case "american":
      if (value > 0) {
        return `+${value}`
      } else {
        return value;
      }
    case "decimal":
      return value.toFixed(2);
    case "fractional":
      return value;
    default:
      return value;
  }
}

// Replacement of the above 'getFormattedLineValue' as we try to deprecate it
export function formatOdds(format, odds) {
  switch(format) {
    case "american":
      if (odds > 0) {
        return `+${odds}`
      } else {
        return odds;
      }
    case "decimal":
      return odds.toFixed(2);
    case "fractional":
    default:
      return odds;
  }
}

export function convertAmericanToDecimal(american) {
  if (american > 0) {
    return (american / 100) + 1;
  } else if (american < 0) {
    return 1 - (100 / american);
  } else {
    return 0;
  }
}

export function convertFractionalToDecimal(fractional) {
  const numerator = Number(fractional.split("/")[0]);
  const denominator = Number(fractional.split("/")[1]);
  return 1 + (numerator / denominator);
}

export function getBookEntryBasedOnPrecedence(books) {
  if (!books) {
    return {};
  }
  if (books['FanDuel']) {
    return books['FanDuel'];
  }
  if (books['DraftKings']) {
    return books['DraftKings'];
  }
  if (books['Caesars']) {
    return books['Caesars'];
  }
  if (books['BetMGM']) {
    return books['BetMGM'];
  }
  // This one can be removed at some point, it's a temporary one
  if (books['PointsBet']) {
    return books['PointsBet'];
  }
  if (books['Sleeper']) {
    return books['Sleeper'];
  }
  return {};
}

export function getPlayerName(player) {
  // if (isDictEmpty(player) || isDictEmpty(player.info)) {
  //   return "";
  // }
  // return `${player.info.firstName.charAt(0)}. ${player.info.lastName}`;
  if (isDictEmpty(player)) {
    return '';
  }
  // Supports passing in either the player record or the playerInfo
  const playerInfo = 'info' in player ? player.info : player;
  if ('firstName' in playerInfo && playerInfo.firstName && playerInfo.firstName.trim() !== '' &&
      'lastName' in playerInfo && playerInfo.lastName && playerInfo.lastName.trim() !== '') {
    return `${playerInfo.firstName.charAt(0)}. ${playerInfo.lastName}`
  }
  if ('fullName' in playerInfo && playerInfo.fullName && playerInfo.fullName.trim() !== '') {
    return playerInfo.fullName;
  }
  return '';
}

export function getPlayerPosition(player, includeHandedness) {
  if (isDictEmpty(player) || !('info' in player) || !('position' in player.info)) {
    return "";
  }
  var position = player.info.position;
  // This only applies to MLB
  if (includeHandedness) {
    if ((player.info.position === "P" || player.info.position === "SP" || player.info.position === "RP") && 'pitchingHand' in player.info) {
      position += ` (${player.info.pitchingHand}H)`;
    } else if ('battingHand' in player.info) {
      position += ` (${player.info.battingHand}H)`;
    }
  }
  return position;
}

export function getFullPlayerName(player) {
  if (isDictEmpty(player) || isDictEmpty(player.info)) {
    return "";
  }
  
  var playerNameParts = [];
  if (player.info.preferredName) {
    playerNameParts.push(player.info.preferredName);
  } else if (player.info.firstName) {
    playerNameParts.push(player.info.firstName);
  }
  if (player.info.lastName) {
    playerNameParts.push(player.info.lastName);
  }

  const name = playerNameParts.join(" ");
  if (name.trim() === "") {
    return getPlayerFullDisplayName(player.info)
  }
  return name;
}

export function getPlayerFullDisplayName(playerInfo) {
  if ('firstName' in playerInfo && playerInfo.firstName && playerInfo.firstName.trim() !== '' &&
      'lastName' in playerInfo && playerInfo.lastName && playerInfo.lastName.trim() !== '') {
    return `${playerInfo.firstName} ${playerInfo.lastName}`
  }
  if ('fullName' in playerInfo && playerInfo.fullName && playerInfo.fullName.trim() !== '') {
    return playerInfo.fullName;
  }
  return '';
}

export function horizontalScrollHandler(parentSelector, leftButtonClass, rightButtonClass) {
  const element = document.querySelector(parentSelector);
  if (element.scrollLeft === 0) {
      // Remove left indicator
      document.querySelector(`.${leftButtonClass}`).style.display = 'none';
  } else {
      // Put back left indicator
      document.querySelector(`.${leftButtonClass}`).style.display = 'block';
  }
  if (element.offsetWidth + element.scrollLeft >= element.scrollWidth) {
      // Remove right indicator
      document.querySelector(`.${rightButtonClass}`).style.display = 'none';
  } else {
      // Put back right indicator
      document.querySelector(`.${rightButtonClass}`).style.display = 'block';
  }
}

export function getMultiFieldValue(dictionary, fields) {
  var value = 0;
  fields.map((field) => {
    // Need to wrap in Number cast because it can return a string from the decimal precision with padding (not ideal but should work)
    value += Number(getDictionaryValue(dictionary, field));
  })
  // This is a pretty bad hack but I don't know how else to fix it quickly without refactoring a bunch of shit
  // Going to use the getDictionaryValue internal mechanism to get the right decimal points but we're going to use the last field's decimal precision
  var tempDictionary = {};
  // Array.at() is not supported in Safari until early to mid 2022 which at the
  //  time of writing this is very recent, meaning there's a good chance some
  //  users won't have updated to that version yet and get errors
  // It is the cleaner option so we should consider switching back to it at some
  //  point or extend the Array prototype to include similar functionality
  //var key = fields.at(-1);
  var key = fields[fields.length - 1];
  if (key.includes(".")) {
    const keySplit = key.split(".");
    key = keySplit[keySplit.length - 1];
  }
  tempDictionary[key] = value;
  value = getDictionaryValue(tempDictionary, key);
  if (isNaN(value)) {
    return 0;
  }
  return value;
}

// gameRecord should be a player game record, we can only re-use this for team if we add score and opposingScore to the team game record
export function getGameResultText(league, gameRecord) {
  switch(league) {
    case "nba":
    case "wnba":
    case "mlb":
    case "ncaaf":
      return `${gameRecord.win === 1 ? "W" : "L"} • ${gameRecord.score}-${gameRecord.opposingScore}`;
    case "nhl":
      return `${gameRecord.win === 1 ? "W" : (gameRecord.overtimeLoss === 1 ? "OTL" : "L")} • ${gameRecord.score}-${gameRecord.opposingScore}`;
    case "nfl":
      return `${gameRecord.win === 1 ? "W" : (gameRecord.loss === 1 ? "L" : "TIE")} • ${gameRecord.score}-${gameRecord.opposingScore}`;
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return `${gameRecord.win === 1 ? "W" : (gameRecord.loss === 1 ? "L" : "D")} • ${gameRecord.score}-${gameRecord.opposingScore}`;
    default:
      return "";
  }
}

// gameRecord should be a team game record
export function getTeamGameResultText(league, gameRecord) {
  switch(league) {
    case "nba":
    case "wnba":
    case "ncaaf":
      return `${gameRecord.win === 1 ? "W" : "L"} • ${gameRecord.cumulativeStats.offensive.points}-${gameRecord.cumulativeStats.defensive.points}`;
    case "mlb":
      return `${gameRecord.win === 1 ? "W" : "L"} • ${gameRecord.cumulativeStats.offensive.runs}-${gameRecord.cumulativeStats.defensive.runs}`;
    case "nhl":
      return `${gameRecord.win === 1 ? "W" : (gameRecord.overtimeLoss === 1 ? "OTL" : "L")} • ${gameRecord.cumulativeStats.offensive.goals}-${gameRecord.cumulativeStats.defensive.goals}`;
    case "nfl":
      return `${gameRecord.win === 1 ? "W" : (gameRecord.loss === 1 ? "L" : "TIE")} • ${gameRecord.cumulativeStats.offensive.points}-${gameRecord.cumulativeStats.defensive.points}`;
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return `${gameRecord.win === 1 ? "W" : (gameRecord.loss === 1 ? "L" : "D")} • ${gameRecord.cumulativeStats.offensive.goals}-${gameRecord.cumulativeStats.defensive.goals}`;
    default:
      return "";
  }
}

export function getRegionAffiliateTitle(regionCode, regionName) {
  // Once we have more territories with restrictions on advertising we should set this to check in a list of those who are restrictive
  if (regionCode === "CA-ON") {
    return `${regionName} Sportsbooks`
  }
  return `${regionName} Bonuses`
}

export function getAffiliateTitle(regionCode) {
  // Once we have more territories with restrictions on advertising we should set this to check in a list of those who are restrictive
  if (regionCode === "CA-ON") {
    return `Sportsbooks`
  }
  return `Bonuses`
}

export function safe(promise) {
  return promise.catch(error => {
    console.log(error);
    console.log(error.stack);
  });
}

export function buildTeamPropRankings(teams, league) {
  var result = {};
  Object.keys(PLAYER_BET_TYPE_ID_TO_NAME[league]).map((propID) => {
      const propMapping = getPlayerPropDefensiveTeamStatPath[league](propID);
      const fields = propMapping.defensiveTeamFields;
      if (fields && fields.length > 0) {
        // Creating a local copy of the teams list
        var localTeams = teams.map(a => ({...a}));
        // Need to iterate to build the value since we have props with multiple fields
        localTeams.map((team) => {
            var value = 0;
            fields.map((field) => {
                value += getDictionaryValue(team, `${field}`);
            })
            team.propValue = value;
        })
        // console.log(localTeams);
  
        // prop -> teamRankMapping
        // teamRankMapping: team -> rank
        var teamRankMapping = {};
        // TODO: add a custom order, for now we will do ASC (lower is better)
        //  This should be updated for stuff like defensive interceptions being better
        // TODO: we could also look into re-using the existing order defined in other places
        // TODO: review block for NBA (I think the defensive blocks for NBA team is the amount of blocks they have had, so the offensive.blocks for team is what the player is going against I think)
        orderListByField(localTeams, 'propValue', propMapping.defensiveSortingOrder);
        localTeams.map((team, index) => {
            teamRankMapping[team.code] = index + 1;
        })
        result[propID] = teamRankMapping
      }
  })
  return result;
}

export function range(size, start, increment) {
  return [...Array(size).keys()].map(i => (i * increment) + start);
}

export function closestMultipleCeil(input, multiple) {
  return multiple * Math.ceil(input/multiple);
}

export function closestMultipleFloor(input, multiple) {
  return multiple * Math.floor(input/multiple);
}

// The double negative is to cover cases where the input is undefined
export function fetchPlayerRecord(host, league, ids, includeMarkets) {
  return Promise.all(ids.map(x => fetch(`${host}/api/${league}/v1/players/bySRGUID/${x}?includeMarkets=${!!includeMarkets}`).then(response => response.status === 200 ? response.json() : null)));
}

// Situation means either "current" or "opening"
export function buildGameMarketStructure(game, market, userAttributes, situation) {
  const lineFormat = getPreferredFormat(userAttributes);
  const targetMarkets = game.markets || {};
  const targetMarket = targetMarkets[market] || {};
  const targetMarketBook = getBookEntryBasedOnPrecedence(targetMarket.books);

  const defaultMarketText = DEFAULT_MARKET_TEXT;
  var totalOverLineText = defaultMarketText;
  var totalOverLine = defaultMarketText;
  var totalOverOddsText = defaultMarketText;
  var totalUnderLineText = defaultMarketText;
  var totalUnderLine = defaultMarketText;
  var totalUnderOddsText = defaultMarketText;
  if (targetMarketBook && targetMarketBook.over && targetMarketBook.over[situation]) {
    if (targetMarketBook.over[situation].value) {
      totalOverLineText = `${"over".charAt(0)}${targetMarketBook.over[situation].value}`
      totalOverLine = targetMarketBook.over[situation].value;
    }
    if (targetMarketBook.over[situation].odds) {
      totalOverOddsText = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, targetMarketBook.over[situation].odds));
    }
  }
  if (targetMarketBook && targetMarketBook.under && targetMarketBook.under[situation]) {
    if (targetMarketBook.under[situation].value) {
      totalUnderLineText = `${"under".charAt(0)}${targetMarketBook.under[situation].value}`
      totalUnderLine = targetMarketBook.under[situation].value;
    }
    if (targetMarketBook.under[situation].odds) {
      totalUnderOddsText = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, targetMarketBook.under[situation].odds));
    }
  }
  return {
    over: {
      line: totalOverLine,
      formattedLine: totalOverLineText,
      odds: totalOverOddsText
    },
    under: {
      line: totalUnderLineText,
      formattedLine: totalUnderLine,
      odds: totalUnderOddsText
    }
  }
}

// Situation means either "current" or "opening"
export function buildTeamMarketStructure(teamData, market, userAttributes, situation) {
  const teamMarkets = teamData.markets || {};
  const defaultMarketText = DEFAULT_MARKET_TEXT;
  const lineFormat = getPreferredFormat(userAttributes);

  const targetMarket = teamMarkets[market] || {};
  const targetBook = getBookEntryBasedOnPrecedence(targetMarket.books);
  // over/under markets such as total, we can re-use the game function (which maybe we should rename to over/under market structure)
  if (targetBook && (targetBook.over || targetBook.under)) {
    return buildGameMarketStructure(teamData, market, userAttributes, situation);
  }

  const result = {
    cover: {
      line: defaultMarketText,
      formattedLine: defaultMarketText,
      odds: defaultMarketText
    },
    draw: {
      line: defaultMarketText,
      formattedLine: defaultMarketText,
      odds: defaultMarketText
    }
  }

  const targets = ["cover", "draw"]
  targets.forEach((target) => {
    // Cover markets such as moneyline and spread
    if (targetBook && target in targetBook && targetBook[target] && situation in targetBook[target] && targetBook[target][situation]) {
      const situationalTarget = targetBook[target][situation];
      if (situationalTarget.odds) {
        result[target].odds = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, situationalTarget.odds));
      }
      // A cover with a line value, such as spread
      if (situationalTarget.value) {
        result[target].line = situationalTarget.value;
        result[target].formattedLine = `${situationalTarget.value > 0 ? "+" : ""}${situationalTarget.value}`;
      } else {
        // A cover without a line such as moneyline, nothing to do
      }
    }
  })

  return result
}

export function buildStandingsRecordString(league, standings) {
  if (!standings) {
    standings = {};
  }
  return {
    nfl: `${standings.wins || 0}-${standings.losses || 0}-${standings.ties || 0}`,
    ncaaf: `${standings.wins || 0}-${standings.losses || 0}`,
    nhl: `${standings.wins || 0}-${standings.losses || 0}-${standings.overtimeLosses || 0}`,
    nba: `${standings.wins || 0}-${standings.losses || 0}`,
    wnba: `${standings.wins || 0}-${standings.losses || 0}`,
    mlb: `${standings.wins || 0}-${standings.losses || 0}`,
    bundesliga: `${standings.wins || 0}-${standings.losses || 0}-${standings.draws || 0}`,
    epl: `${standings.wins || 0}-${standings.losses || 0}-${standings.draws || 0}`,
    laliga: `${standings.wins || 0}-${standings.losses || 0}-${standings.draws || 0}`,
    ligue1: `${standings.wins || 0}-${standings.losses || 0}-${standings.draws || 0}`,
    seriea: `${standings.wins || 0}-${standings.losses || 0}-${standings.draws || 0}`,
    "brazil-serie-a": `${standings.wins || 0}-${standings.losses || 0}-${standings.draws || 0}`,
    mls: `${standings.wins || 0}-${standings.losses || 0}-${standings.draws || 0}`,
    euro: `${standings.wins || 0}-${standings.losses || 0}-${standings.draws || 0}`
  }[league]
}

export function formatStandingsStreak(league, streak) {
  if (!streak || !streak.type) {
    return ""
  }
  return {
    // TODO: review NHL and NFL streak type
    nfl: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    ncaaf: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    nhl: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    nba: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    wnba: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    mlb: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    bundesliga: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    epl: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    laliga: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    ligue1: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    seriea: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    "brazil-serie-a": `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    mls: `${streak.type.charAt(0).toUpperCase()}${streak.length}`,
    euro: `${streak.type.charAt(0).toUpperCase()}${streak.length}`
  }[league]
}

export function getMatchupTeamGameRecords(host, league, teamCode, opposingTeamCode, currentSeason) {
  return fetch(`${host}/api/${league}/v1/games/filteredForTeam?teamCode=${teamCode}&opponent=${opposingTeamCode}&sortingOrder=DESC&timeframe=RANGE_${currentSeason - 2}_${currentSeason}`).then(response => response.json())
}

export function getTeamAverages(host, league) {
  return fetch(`${host}/api/${league}/v1/teams/averages`).then(response => response.json());
}

export function getLast5TeamGameRecords(host, league, teamCode) {
  return fetch(`${host}/api/${league}/v1/games/filteredForTeam?teamCode=${teamCode}&timeframe=LAST_5&sortingOrder=DESC`).then(response => response.json());
}

export function getCombinedStandings(league, teamGameRecords) {
  switch(league) {
    case "nba":
    case "wnba":
    case "mlb":
    case "ncaaf":
      return teamGameRecords.map(x => {return {wins: x.win, losses: x.loss}})
                            .reduce((previous, current) => {return {wins: previous.wins + current.wins, losses: previous.losses + current.losses}}, {wins: 0, losses: 0})
    case "nfl":
      return teamGameRecords.map(x => {return {wins: x.win, losses: x.loss, ties: x.tie}})
                            .reduce((previous, current) => {return {wins: previous.wins + current.wins, losses: previous.losses + current.losses, ties: previous.ties + current.ties}}, {wins: 0, losses: 0, ties: 0})
    case "nhl": 
      return teamGameRecords.map(x => {return {wins: x.win, losses: x.loss, overtimeLosses: x.overtimeLoss}})
                            .reduce((previous, current) => {return {wins: previous.wins + current.wins, losses: previous.losses + current.losses, overtimeLosses: previous.overtimeLosses + current.overtimeLosses}}, {wins: 0, losses: 0, overtimeLosses: 0})
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return teamGameRecords.map(x => {return {wins: x.win, losses: x.loss, draws: x.draw}})
                            .reduce((previous, current) => {return {wins: previous.wins + current.wins, losses: previous.losses + current.losses, draws: previous.draws + current.draws}}, {wins: 0, losses: 0, draws: 0})
    default:
      return {}
  }
}

export function getCombinedStandingsGraphDictionary(league, teamGameRecords) {
  const combinedStandings = getCombinedStandings(league, teamGameRecords);
  switch(league) {
    case "nba":
    case "wnba":
    case "mlb":
    case "ncaaf":
      return {W: combinedStandings.wins, L: combinedStandings.losses}
    case "nfl":
      return {W: combinedStandings.wins, L: combinedStandings.losses, T: combinedStandings.ties}
    case "nhl": 
    return {W: combinedStandings.wins, L: combinedStandings.losses, OTL: combinedStandings.overtimeLosses}
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return {W: combinedStandings.wins, L: combinedStandings.losses, D: combinedStandings.draws}
    default:
      return {}
  }
}

export function getCombinedHitRecords(teamGameRecords) {
  return teamGameRecords.map(record => record.hitRecords).reduce((previous, current) => {
      return {
        againstTheSpread: combineHitRecordEntries(previous.againstTheSpread, current.againstTheSpread),
        awayAgainstTheSpread: combineHitRecordEntries(previous.awayAgainstTheSpread, current.awayAgainstTheSpread),
        awayTotalOver: combineHitRecordEntries(previous.awayTotalOver, current.awayTotalOver),
        homeAgainstTheSpread: combineHitRecordEntries(previous.homeAgainstTheSpread, current.homeAgainstTheSpread),
        homeTotalOver: combineHitRecordEntries(previous.homeTotalOver, current.homeTotalOver),
        totalOver: combineHitRecordEntries(previous.totalOver, current.totalOver)
      }
    },
    {
      againstTheSpread: DEFAULT_HIT_RECORD_ENTRY,
      awayAgainstTheSpread: DEFAULT_HIT_RECORD_ENTRY,
      awayTotalOver: DEFAULT_HIT_RECORD_ENTRY,
      homeAgainstTheSpread: DEFAULT_HIT_RECORD_ENTRY,
      homeTotalOver: DEFAULT_HIT_RECORD_ENTRY,
      totalOver: DEFAULT_HIT_RECORD_ENTRY
    }
  )
}

export function combineHitRecordEntries(entry1, entry2) {
  return {
    games: entry1.games + entry2.games,
    hits: entry1.hits + entry2.hits,
    misses: entry1.misses + entry2.misses,
    pushes: entry1.pushes + entry2.pushes,
    hitRate: entry1.games + entry2.games === 0 ? 0 : ((entry1.hits + entry2.hits) / (entry1.games + entry2.games)) * 100
  }
}

export function getPlayersToWatch(host, league, teamCode, opposingTeamCode) {
  return fetch(`${host}/api/${league}/v1/players/forPlaybookComparison?teamCode=${teamCode}&opponent=${opposingTeamCode}`).then(response => response.json());
}

export function buildPlayersToWatchSections(league, awayPlayers, homePlayers) {
  switch(league) {
    case "mlb":
      return {
        "Player Stat": {
          stats: {
            "cumulativeStats": {
              "AB": "cumulativeStats.hitting.atBats",
              "R": "cumulativeStats.hitting.runs",
              "H": "cumulativeStats.hitting.hits",
              "1B": "cumulativeStats.hitting.singles",
              "2B": "cumulativeStats.hitting.doubles",
              "3B": "cumulativeStats.hitting.triples",
              "HR": "cumulativeStats.hitting.homeRuns",
              "ISO": "cumulativeStats.hitting.isolatedPower",
              "RBI": "cumulativeStats.hitting.runsBattedIn",
              "TB": "cumulativeStats.hitting.totalBases",
              "BB": "cumulativeStats.hitting.walks",
              "SO": "cumulativeStats.hitting.strikeouts",
              "AVG": "cumulativeStats.hitting.battingAverage",
              "OBP": "cumulativeStats.hitting.onBasePercentage",
              "SLG": "cumulativeStats.hitting.sluggingPercentage",
              "OPS": "cumulativeStats.hitting.onBasePlusSluggingPercentage"
            }, 
            "averageStats": {
              "AB": "averageStats.hitting.atBats",
              "R": "averageStats.hitting.runs",
              "H": "averageStats.hitting.hits",
              "1B": "averageStats.hitting.singles",
              "2B": "averageStats.hitting.doubles",
              "3B": "averageStats.hitting.triples",
              "HR": "averageStats.hitting.homeRuns",
              "ISO": "averageStats.hitting.isolatedPower",
              "RBI": "averageStats.hitting.runsBattedIn",
              "TB": "averageStats.hitting.totalBases",
              "BB": "averageStats.hitting.walks",
              "SO": "averageStats.hitting.strikeouts",
              "AVG": "averageStats.hitting.battingAverage",
              "OBP": "averageStats.hitting.onBasePercentage",
              "SLG": "averageStats.hitting.sluggingPercentage",
              "OPS": "averageStats.hitting.onBasePlusSluggingPercentage"
            }
          },
          awayPlayers: awayPlayers, 
          homePlayers: homePlayers
        }
      };
    case "nba":
    case "wnba":
      return {
        "Player Stat": {
          stats: {
            "cumulativeStats": {
              "PTS": "cumulativeStats.points",
              "FT%": "cumulativeStats.freeThrowPercentage",
              "FG%": "cumulativeStats.fieldGoalPercentage",
              "3PT%": "cumulativeStats.threePointPercentage",
              "REB": "cumulativeStats.rebounds",
              "AST": "cumulativeStats.assists",
              "STL": "cumulativeStats.steals",
              "BLK": "cumulativeStats.blocks"
            }, 
            "averageStats": {
              "PTS": "averageStats.points",
              "FT%": "averageStats.freeThrowPercentage",
              "FG%": "averageStats.fieldGoalPercentage",
              "3PT%": "averageStats.threePointPercentage",
              "REB": "averageStats.rebounds",
              "AST": "averageStats.assists",
              "STL": "averageStats.steals",
              "BLK": "averageStats.blocks"
            }
          },
          awayPlayers: awayPlayers, 
          homePlayers: homePlayers
        }
      };
    case "nhl":
      const awaySkaters = {};
      const awayGoalies = {};

      const homeSkaters = {};
      const homeGoalies = {};
      Object.keys(awayPlayers).forEach((timeframe) => {
        const awayPlayersInternal = awayPlayers[timeframe];
        const homePlayersInternal = homePlayers[timeframe];
        awaySkaters[timeframe] = [];              
        awayGoalies[timeframe] = [];
        awayPlayersInternal.forEach((player) => {
            if (player.info.position === "G") {
                awayGoalies[timeframe].push(player);
            } else {
                awaySkaters[timeframe].push(player);
            }
        });

        homeSkaters[timeframe] = [];              
        homeGoalies[timeframe] = [];
        homePlayersInternal.forEach((player) => {
            if (player.info.position === "G") {
                homeGoalies[timeframe].push(player);
            } else {
                homeSkaters[timeframe].push(player);
            }
        });
      });
      return {
        "Player Stat": {
          stats: {
            "cumulativeStats": {
              "PTS": "cumulativeStats.points",
              "G": "cumulativeStats.goals",
              "A": "cumulativeStats.assists",
              "SOG": "cumulativeStats.shotsOnGoal",
              "SAT": "cumulativeStats.shotAttempts",
              "+/-": "cumulativeStats.plusMinus",
              "PPP": "cumulativeStats.powerplayPoints",
              "SHP": "cumulativeStats.shorthandedPoints",
              "PIM": "cumulativeStats.penaltyMinutes",
              "BLK": "cumulativeStats.defensiveBlocks"
            }, 
            "averageStats": {
              "PTS": "averageStats.points",
              "G": "averageStats.goals",
              "A": "averageStats.assists",
              "SOG": "averageStats.shotsOnGoal",
              "SAT": "averageStats.shotAttempts",
              "+/-": "averageStats.plusMinus",
              "PPP": "averageStats.powerplayPoints",
              "SHP": "averageStats.shorthandedPoints",
              "PIM": "averageStats.penaltyMinutes",
              "BLK": "averageStats.defensiveBlocks"
            }
          },
          awayPlayers: awaySkaters, 
          homePlayers: homeSkaters
        },
        "Goalie Stat": {
          stats: {
            "cumulativeStats": {
              "WINS": "cumulativeStats.wins",
              "SA": "cumulativeStats.shotsAgainst",
              "SAVES": "cumulativeStats.shotsSaved",
              "SV%": "cumulativeStats.savePercentage",
              "GA": "cumulativeStats.goalsAllowed",
              "SHUTOUTS": "cumulativeStats.shutouts"
            }, 
            "averageStats": {
              "WINS": "averageStats.wins",
              "SA": "averageStats.shotsAgainst",
              "SAVES": "averageStats.shotsSaved",
              "SV%": "averageStats.savePercentage",
              "GA": "averageStats.goalsAllowed",
              "SHUTOUTS": "averageStats.shutouts"
            }
          },
          awayPlayers: awayGoalies, 
          homePlayers: homeGoalies
        }
      };
    case "nfl":
    case "ncaaf":
      const awayPassingPlayers = {};                
      const awayRushingPlayers = {};
      const awayReceivingPlayers = {};
      const awayKickingPlayers = {};
      const homePassingPlayers = {};             
      const homeRushingPlayers = {};
      const homeReceivingPlayers = {};
      const homeKickingPlayers = {};
      Object.keys(awayPlayers).forEach((timeframe) => {
          const awayPlayersInternal = awayPlayers[timeframe];
          const homePlayersInternal = homePlayers[timeframe];

          awayPassingPlayers[timeframe] = [];              
          awayRushingPlayers[timeframe] = [];
          awayReceivingPlayers[timeframe] = [];
          awayKickingPlayers[timeframe] = [];
          awayPlayersInternal.forEach((player) => {
              if (player.info.position === "QB") {
                  awayPassingPlayers[timeframe].push(player);
              }
              //"QB", "RB", "FB", "HB"
              if (player.info.position === "QB" || player.info.position === "RB" || player.info.position === "FB" || player.info.position === "HB") {
                  awayRushingPlayers[timeframe].push(player);
              }
              //"RB", "FB", "HB", "WR", "TE"
              if (player.info.position === "RB" || player.info.position === "FB" || player.info.position === "HB" || player.info.position === "WR" || player.info.position === "TE") {
                  awayReceivingPlayers[timeframe].push(player);
              }
              //"P", "K"
              if (player.info.position === "P" || player.info.position === "K") {
                  awayKickingPlayers[timeframe].push(player);
              }
          });

          homePassingPlayers[timeframe] = [];              
          homeRushingPlayers[timeframe] = [];
          homeReceivingPlayers[timeframe] = [];
          homeKickingPlayers[timeframe] = [];
          homePlayersInternal.forEach((player) => {
              if (player.info.position === "QB") {
                  homePassingPlayers[timeframe].push(player);
              }
              //"QB", "RB", "FB", "HB"
              if (player.info.position === "QB" || player.info.position === "RB" || player.info.position === "FB" || player.info.position === "HB") {
                  homeRushingPlayers[timeframe].push(player);
              }
              //"RB", "FB", "HB", "WR", "TE"
              if (player.info.position === "RB" || player.info.position === "FB" || player.info.position === "HB" || player.info.position === "WR" || player.info.position === "TE") {
                  homeReceivingPlayers[timeframe].push(player);
              }
              //"P", "K"
              if (player.info.position === "P" || player.info.position === "K") {
                  homeKickingPlayers[timeframe].push(player);
              }
          });
      });
      return {
        "Passing Stat": {
          stats: {
            "cumulativeStats": {
              "PASS RTG": "cumulativeStats.rating",
              "COMP": "cumulativeStats.passingCompletions",
              "ATT": "cumulativeStats.passingAttempts",
              "COMP %": "cumulativeStats.completionPercentage",
              "YDS": "cumulativeStats.passingYards",
              "TD": "cumulativeStats.passingTouchdowns",
              "INT": "cumulativeStats.interceptions",
              "RZ ATT": "cumulativeStats.redzonePassingAttempts",
              "RZ COMP": "cumulativeStats.redzoneCompletions",
              "RZ TD": "cumulativeStats.redzonePassingTouchdowns",
              "RZ COMP %": "cumulativeStats.redzoneCompletionPercentage"
            }, 
            "averageStats": {
              "PASS RTG": "averageStats.rating",
              "COMP": "averageStats.passingCompletions",
              "ATT": "averageStats.passingAttempts",
              "COMP %": "averageStats.completionPercentage",
              "YDS": "averageStats.passingYards",
              "TD": "averageStats.passingTouchdowns",
              "INT": "averageStats.interceptions",
              "RZ ATT": "averageStats.redzonePassingAttempts",
              "RZ COMP": "averageStats.redzoneCompletions",
              "RZ TD": "averageStats.redzonePassingTouchdowns",
              "RZ COMP %": "averageStats.redzoneCompletionPercentage"
            }
          },
          awayPlayers: awayPassingPlayers, 
          homePlayers: homePassingPlayers
        },
        "Receiving Stat": {
          stats: {
            "cumulativeStats": {
              "TGT": "cumulativeStats.targets",
              "REC": "cumulativeStats.receptions",
              "YDS": "cumulativeStats.receivingYards",
              "TD": "cumulativeStats.receivingTouchdowns",
              "YDS/REC": "cumulativeStats.receivingYardsPerReception",
              "RZ TGT": "cumulativeStats.redzoneTargets",
              "RZ REC": "cumulativeStats.redzoneReceptions",
              "RZ TD": "cumulativeStats.redzoneReceivingTouchdowns"
            }, 
            "averageStats": {
              "TGT": "averageStats.targets",
              "REC": "averageStats.receptions",
              "YDS": "averageStats.receivingYards",
              "TD": "averageStats.receivingTouchdowns",
              "YDS/REC": "averageStats.receivingYardsPerReception",
              "RZ TGT": "averageStats.redzoneTargets",
              "RZ REC": "averageStats.redzoneReceptions",
              "RZ TD": "averageStats.redzoneReceivingTouchdowns"
            }
          },
          awayPlayers: awayReceivingPlayers, 
          homePlayers: homeReceivingPlayers
        },
        "Rushing Stat": {
          stats: {
            "cumulativeStats": {
              "ATT": "cumulativeStats.rushingAttempts",
              "YDS": "cumulativeStats.rushingYards",
              "TD": "cumulativeStats.rushingTouchdowns",
              "YDS/ATT": "cumulativeStats.rushingYardsPerAttempt",
              "RZ ATT": "cumulativeStats.redzoneRushingAttempts",
              "RZ TD": "cumulativeStats.redzoneRushingTouchdowns"
            }, 
            "averageStats": {
              "ATT": "averageStats.rushingAttempts",
              "YDS": "averageStats.rushingYards",
              "TD": "averageStats.rushingTouchdowns",
              "YDS/ATT": "averageStats.rushingYardsPerAttempt",
              "RZ ATT": "averageStats.redzoneRushingAttempts",
              "RZ TD": "averageStats.redzoneRushingTouchdowns"
            }
          },
          awayPlayers: awayRushingPlayers, 
          homePlayers: homeRushingPlayers
        },
        "Kicking Stat": {
          stats: {
            "cumulativeStats": {
              "PTS": "cumulativeStats.pointsKicked",
              "FGA": "cumulativeStats.fieldGoalAttempts",
              "FGM": "cumulativeStats.fieldGoalsMade",
              "FG%": "cumulativeStats.fieldGoalPercentage",
              "XPA": "cumulativeStats.extraPointAttempts",
              "XPM": "cumulativeStats.extraPointsMade",
              "XP%": "cumulativeStats.extraPointPercentage",
              "FG (0-19)": "cumulativeStats.extraShortRangeFieldGoals",
              "FG (20-29)": "cumulativeStats.shortRangeFieldGoals",
              "FG (30-39)": "cumulativeStats.mediumRangeFieldGoals",
              "FG (40-49)": "cumulativeStats.longRangeFieldGoals",
              "FG (50+)": "cumulativeStats.extraLongRangeFieldGoals"
            }, 
            "averageStats": {
              "PTS": "averageStats.pointsKicked",
              "FGA": "averageStats.fieldGoalAttempts",
              "FGM": "averageStats.fieldGoalsMade",
              "FG%": "averageStats.fieldGoalPercentage",
              "XPA": "averageStats.extraPointAttempts",
              "XPM": "averageStats.extraPointsMade",
              "XP%": "averageStats.extraPointPercentage",
              "FG (0-19)": "averageStats.extraShortRangeFieldGoals",
              "FG (20-29)": "averageStats.shortRangeFieldGoals",
              "FG (30-39)": "averageStats.mediumRangeFieldGoals",
              "FG (40-49)": "averageStats.longRangeFieldGoals",
              "FG (50+)": "averageStats.extraLongRangeFieldGoals"
            }
          },
          awayPlayers: awayKickingPlayers, 
          homePlayers: homeKickingPlayers
        }
      };
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      const awayOutfielders = {};
      const awayGoalkeepers = {};

      const homeOutfielders = {};
      const homeGoalkeepers = {};
      Object.keys(awayPlayers).forEach((timeframe) => {
        const awayPlayersInternal = awayPlayers[timeframe];
        const homePlayersInternal = homePlayers[timeframe];
        awayOutfielders[timeframe] = [];              
        awayGoalkeepers[timeframe] = [];
        awayPlayersInternal.forEach((player) => {
            if (player.info.position === "goalkeeper") {
                awayGoalkeepers[timeframe].push(player);
            } else {
                awayOutfielders[timeframe].push(player);
            }
        });

        homeOutfielders[timeframe] = [];              
        homeGoalkeepers[timeframe] = [];
        homePlayersInternal.forEach((player) => {
            if (player.info.position === "goalkeeper") {
                homeGoalkeepers[timeframe].push(player);
            } else {
                homeOutfielders[timeframe].push(player);
            }
        });
      });
      return {
        "Outfielder Stat": {
          stats: {
            "cumulativeStats": {
              "G": "cumulativeStats.goals",
              "A": "cumulativeStats.assists",
              "SOT": "cumulativeStats.shotsOnTarget",
              "SHO": "cumulativeStats.totalShots",
              "PAS": "cumulativeStats.totalPasses",
              // "CC": "cumulativeStats.chancesCreated",
              "DRI": "cumulativeStats.dribblesCompleted",
              "TCK": "cumulativeStats.totalTackles",
              "FLS": "cumulativeStats.foulsCommitted",
              "YEL": "cumulativeStats.yellowCards",
              "RED": "cumulativeStats.redCards"
            }, 
            "averageStats": {
              "G": "averageStats.goals",
              "A": "averageStats.assists",
              "SOT": "averageStats.shotsOnTarget",
              "SHO": "averageStats.totalShots",
              "PAS": "averageStats.totalPasses",
              // "CC": "averageStats.chancesCreated",
              "DRI": "averageStats.dribblesCompleted",
              "TCK": "averageStats.totalTackles",
              "FLS": "averageStats.foulsCommitted",
              "YEL": "averageStats.yellowCards",
              "RED": "averageStats.redCards"
            }
          },
          awayPlayers: awayOutfielders, 
          homePlayers: homeOutfielders
        },
        "Goalie Stat": {
          stats: {
            "cumulativeStats": {
              "GA": "cumulativeStats.goalsConceded",
              "SAV": "cumulativeStats.shotsFacedSaved",
              "SA": "cumulativeStats.shotsFacedTotal",
              "YEL": "cumulativeStats.yellowCards",
              "RED": "cumulativeStats.redCards"
            }, 
            "averageStats": {
              "GA": "averageStats.goalsConceded",
              "SAV": "averageStats.shotsFacedSaved",
              "SA": "averageStats.shotsFacedTotal",
              "YEL": "averageStats.yellowCards",
              "RED": "averageStats.redCards"
            }
          },
          awayPlayers: awayGoalkeepers, 
          homePlayers: homeGoalkeepers
        }
      };
    default:
      return {};
  }
}

export function getPositionGroups(league, players) {
  switch(league) {
    case "nhl":
      return [
        {
          title: 'Forwards',
          data: players.filter(x => x.info.position && ["C", "LW", "RW"].includes(x.info.position))
        },
        {
          title: 'Defensemen',
          data: players.filter(x => x.info.position && x.info.position === "D")
        },
        {
          title: 'Goalies',
          data: players.filter(x => x.info.position && x.info.position === "G")
        }
      ];
    case "nfl":
    case "ncaaf":
      return [
        {
          title: 'Quarterbacks',
          data: players.filter(x => x.info.position && x.info.position === "QB")
        },
        {
          title: 'Running Backs',
          data: players.filter(x => x.info.position && x.info.position === "RB")
        },
        {
          title: 'Wide Receivers',
          data: players.filter(x => x.info.position && x.info.position === "WR")
        },
        {
          title: 'Tight Ends',
          data: players.filter(x => x.info.position && x.info.position === "TE")
        },
        {
          title: 'Kickers',
          data: players.filter(x => x.info.position && x.info.position === "K")
        }
      ]
    case "nba":
    case "wnba":
      return [
        {
          title: 'Players',
          data: players
        }
      ];
    case "mlb":
      return [
        {
          title: 'Pitchers',
          data: players.filter(x => x.info.position.toUpperCase() === "SP" || x.info.position.toUpperCase() === "RP")
        },
        {
          title: 'Hitters',
          data: players.filter(x => x.info.position.toUpperCase() !== "SP" && x.info.position.toUpperCase() !== "RP")
        }
      ];
    case "bundesliga":
    case "epl":
    case "laliga":
    case "ligue1":
    case "seriea":
    case "brazil-serie-a":
    case "mls":
    case "euro":
      return [
        {
          title: 'Forwards',
          data: players.filter(x => x => x.info.position && x.info.position.toLowerCase() === "forwards")
        },
        {
          title: 'Midfielders',
          data: players.filter(x => x => x.info.position && x.info.position.toLowerCase() === "midfielder")
        },
        {
          title: 'Defenders',
          data: players.filter(x => x => x.info.position && x.info.position.toLowerCase() === "defender")
        },
        {
          title: 'Goalies',
          data: players.filter(x => x => x.info.position && x.info.position.toLowerCase() === "goalkeeper")
        }
      ];
    default:
      return [];
  }
}

export function updateUserAttributes(host, userToken, userAttributes) {
  const postRequestInfo = {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userToken
      },
      body: JSON.stringify({userAttributes: userAttributes})
  };
  return fetch(`${host}/api/users/v1/update`, postRequestInfo)
}

export function sendEmailVerificationCode(host, email) {
  return fetch(`${host}/api/users/v1/sendEmailVerificationCode?email=${encodeURIComponent(email)}`, {method: 'PUT'});
}

export function verifyEmail(host, email, code) {
  return fetch(`${host}/api/users/v1/verifyEmail?email=${encodeURIComponent(email)}&hash=${code}`, {method: 'PUT'});
}

export function isSoccer(league) {
  return Object.keys(SOCCER_LEAGUES).includes(league);
}

// TODO: review places where this is used to set format=weekly in API call, we may be able to just remove that and change the default bahavior in back-end 
export function isWeeklyCompetition(league) {
  return SUPPORTED_LEAGUES_METADATA[league].scheduleFormat === SCHEDULE_FORMAT_WEEKLY;
}

// We're not adding the playbook for new sports or leagues
export function isPlaybookSupported(league) {
  return ['nfl', 'nhl', 'nba', 'wnba', 'mlb', 'epl', 'bundesliga', 'laliga', 'seriea', 'ligue1', 'mls', 'euro', 'brazil-serie-a'].includes(league)
}

export function getPlayerId(playerInfo) {
  if ("SRGUID" in playerInfo && playerInfo.SRGUID && playerInfo.SRGUID.trim() !== "") {
    return playerInfo.SRGUID;
  }
  if ("SRID" in playerInfo && playerInfo.SRID && playerInfo.SRID.trim() !== "") {
      return playerInfo.SRID;
  }
  return "";
}

export function getTeamDisplayName(league, teamInfo) {
  if (isSoccer(league)) {
    return teamInfo.name
  }
  return `${teamInfo.city} ${teamInfo.name}`
}

export function getTeamCityDisplayName(league, teamInfo) {
  if (isSoccer(league)) {
    return teamInfo.name;
  }
  return teamInfo.city;
}

export function getPlayerWeight(playerInfo) {
  if (!playerInfo) {
    return "";
  }
  if ("weightUnit" in playerInfo && playerInfo.weightUnit && playerInfo.weightUnit.trim() !== "") {
    return `${playerInfo.weight} ${playerInfo.weightUnit}`;
  }
  return `${playerInfo.weight} lbs`;
}

export function getRankDisplayName(team) {
  if (!team || !'standings' in team) {
    return "";
  }

  // Trying to cover all standings separations and rankings possible in any league
  if ('division' in team.standings && team.standings.division) {
    return `${team.standings.division} - ${addOrdinalNumberSuffix(team.standings.divisionRank)}`
  }
  if ('conference' in team.standings && team.standings.conference) {
    return `${team.standings.conference} - ${addOrdinalNumberSuffix(team.standings.conferenceRank)}`
  }
  if ('league' in team.standings && team.standings.league) {
    return `${team.standings.league} - ${addOrdinalNumberSuffix(team.standings.leagueRank)}`
  }
  // Default to overall rank
  if ("overallRank" in team.standings && team.standings.overallRank) {
    return `${addOrdinalNumberSuffix(team.standings.overallRank)}`
  } else if ("rank" in team.standings && team.standings.rank) {
    return `${addOrdinalNumberSuffix(team.standings.rank)}`
  }

  // If we haven't figured it out by this point just return an empty string
  return "";
}

// Iterates over the dictionary and swaps the keys with a specific value in the object
// dict => the dictionary to operate on
// entryKey => the key within each object to use as a swapping point
export function swapDictionary(dict, entryKey) {
  var newDictionary = {};
  Object.keys(dict).forEach((key) => {
    newDictionary[dict[key][entryKey]] = key;
  })
  return newDictionary;
}

// Input must be in RGBA format otherwise the same color that is passed as input will be returned without modification
export function setOpacity(color, opacity) {
  if (color.startsWith("#")) {
    console.warn("Hex color provided as input to set its opacity. This is not currently supported.")
    return color;
  }
  if (!color.startsWith("rgba")) {
    console.warn("Non-rgba format provided. Returning input color.")
    return color;
  }
  const regex = /,[1-9]\d*(\.\d+)?\)/i;
  return color.replace(regex, `,${opacity})`)
}

export function getGameTitle(league, game) {
  var gameTitle = `${game.awayTeamData.info.code} @ ${game.homeTeamData.info.code}`;
  const gameIdParts = game.id.split("-");
  if (league === "mlb" && gameIdParts.length > 3) {
      const gameNumber = gameIdParts[3];
      gameTitle += ` (${gameNumber})`;
  }
  return gameTitle;
}

export function getOddsFromCard(card, userAttributes) {
  const odds = getOddsObjectFrom(card);
  return getFormattedLineValue(
      getPreferredFormat(userAttributes),
      getFormattedOddValue(userAttributes, odds)
  )
}

export function getOddsObjectFrom(card) {
  return getLineOutcomeFromCard(card).odds
}

export function getLineOutcomeFromCard(card) {
  const book = card.book;
  const outcome = card.outcome;
  var lineOutcome = card.market.books[book][outcome].current
  if (card.alternate) {
    const line = card.line;
    if (!isDictEmpty(card.market.books[book][outcome].alternates) && line in card.market.books[book][outcome].alternates) {
      lineOutcome = card.market.books[book][outcome].alternates[line]
    }
  }
  return lineOutcome;
}

const mapping = {};
mapping[RECENT_FORM_TREND_OPTION] = ["RECENT_FORM"]
mapping[HEAD_TO_HEAD_TREND_OPTION] = ["HEAD_TO_HEAD"]
mapping[HOME_AWAY_SPLIT_TREND_OPTION] = ["HOME_SPLIT", "AWAY_SPLIT"]
mapping[OPPONENT_RANK_TREND_OPTION] = ["OPPONENT"]
export function getNarrativeIds(narrativeDisplayName) {
    return mapping[narrativeDisplayName]
}

export function getGamesFilter(options, annotations) {
  var gameOptions = {};
  gameOptions[ALL_GAMES_FILTER_OPTION] = ALL_GAMES_FILTER_OPTION;
  return {
      isActive: false,
      options: Object.assign(gameOptions, options),
      selectedValue: [ALL_GAMES_FILTER_OPTION],
      annotations: annotations,
      filterType: "multiple"
  }
}

export function getOverUnderFilter() {
  return {
      isActive: false,
      options: ["Over+Under", "Over", "Under"],
      selectedValue: "Over+Under",
      filterType: "single"
  }
}

export function getBetTypeFilter(options) {
  return {
      isActive: false,
      options: [ALL_BETS_MARKET_NAME].concat(options),
      selectedValue: ALL_BETS_MARKET_NAME,
      filterType: "single"
  }
}

export function getTrendsFilter() {
  return {
      isActive: false,
      options: [ALL_TRENDS_OPTION, RECENT_FORM_TREND_OPTION, HEAD_TO_HEAD_TREND_OPTION, HOME_AWAY_SPLIT_TREND_OPTION, OPPONENT_RANK_TREND_OPTION],
      selectedValue: ALL_TRENDS_OPTION,
      filterType: "single"
  }
}

export function getBetTypeOptions(league, type) {
  if (type === "player") {
      return Object.keys(PLAYER_BET_TYPES_REVERSE_MAPPING[league]).filter(prop => !SCREENER_ONLY_PROPS[league].includes(prop));
  } else if (type === "team") {
      return Object.keys(TEAM_BET_TYPE_REVERSE_MAPPING[league]);
  }
}

function getBetId(league, type, betName) {
  if (type === "player") {
      return PLAYER_BET_TYPES_REVERSE_MAPPING[league][betName];
  } else if (type === "team") {
      return TEAM_BET_TYPE_REVERSE_MAPPING[league][betName];
  }
  return "";
}

// multiSelection:bool -> if the filters allow multiple options to be selected
export function filterCards(league, cards, filters, type, multiSelection) {
  return cards.filter((card) => {
    if (type !== "player" && type !== "team" && type !== "parlay") {
      return false;
    }
    // O/U filter
    if (OVER_UNDER_FILTER in filters) {
      if (!multiSelection && !(filters[OVER_UNDER_FILTER].selectedValue === "Over+Under" || filters[OVER_UNDER_FILTER].selectedValue.toLowerCase() === card.outcome)) {
        return false;
      }
      if (multiSelection && !filters[OVER_UNDER_FILTER].selectedValues.some((overUnderFilter) => overUnderFilter === "Over+Under" || overUnderFilter.toLowerCase() === card.outcome)) {
        return false;
      }
    }
    
    // Bet type filter
    if (BET_TYPE_FILTER in filters) {
      if (!multiSelection) {
        const betId = getBetId(league, type, filters[BET_TYPE_FILTER].selectedValue)
        if (!(filters[BET_TYPE_FILTER].selectedValue === ALL_BETS_MARKET_NAME || betId === card.market.name)) {
          return false;
        }
      } else {
        if (!filters[BET_TYPE_FILTER].selectedValues.some((betTypeFilter) => betTypeFilter === ALL_BETS_MARKET_NAME || getBetId(league, type, betTypeFilter) === card.market.name)) {
          return false;
        }
      }
    }

    // Trends filter
    if (TRENDS_FILTER in filters) {
      if (!multiSelection && !(filters[TRENDS_FILTER].selectedValue === "All Trends" || card.narratives.some((narrative) => getNarrativeIds(filters[TRENDS_FILTER].selectedValue).includes(narrative)))) {
        return false;
      }
      if (multiSelection && !filters[TRENDS_FILTER].selectedValues.some((trendsFilter) => trendsFilter === "All Trends" || card.narratives.some((narrative) => getNarrativeIds(trendsFilter).includes(narrative)))) {
        return false;
      }
    }

    // Games filter
    if (GAMES_FILTER in filters) {
      const selectedGames = multiSelection ? filters[GAMES_FILTER].selectedValues : filters[GAMES_FILTER].selectedValue;
      // Build a dictionary swapping the key and value of the filter options which will allow us to use the 'in' operator to check for presence rather than iterating a list
      const gamesFilter = Object.fromEntries(selectedGames.map(x => [filters[GAMES_FILTER].options[x], x]))
      if (!(ALL_GAMES_FILTER_OPTION in gamesFilter || card.gameId in gamesFilter)) {
        return false;
      }
    }

    // We could make the assumption that anything containing parlay-related filters can be assumed to be a parlay trend 
    //  but wrapping it in the parlay type check is just a bit safer
    if (type === "parlay") {
      // Leg count filter
      if (PARLAY_LEGS_COUNT_FILTER in filters) {
        const filterSelection = filters[PARLAY_LEGS_COUNT_FILTER].selectedValue;
        // Ideally we would have the option as just the number to do a direct comparison but if we do that we only see the number in the filter selection visual which doesn't tell the user much information
        // If we change the design we could do it but right now we need options with text after the number
        // parseInt will ignore any non-numerical data so we can use it to pick out the numbers in the filter option
        if (filterSelection !== ALL_LEG_COUNTS_OPTION && card.legs.length !== parseInt(filterSelection)) {
          return false;
        }
      }
  
      // Leg type filter
      if (PARLAY_LEGS_TYPE_FILTER in filters) {
        const filterSelection = filters[PARLAY_LEGS_TYPE_FILTER].selectedValue;
        if (!card.legs.every(x => x.type === filterSelection.toLowerCase())) {
          return false;
        }
      }
    }

    return true;
  })
}

export function generateDummyPlay(type, home, insights, outcome, market, line, odds, narratives, team, opposingTeam, player) {
  return {
    type: type,
    book: 'dummy',
    home: home,
    insights: insights,
    outcome: outcome,
    market: {
        name: market,
        books: {
            dummy: {
                over: {
                    current: {
                        value: line,
                        odds: odds
                    }
                }
            }
        }
    },
    narratives: narratives,
    opposingTeam: opposingTeam,
    player: player,
    team: team
  }
}

export function getFilterQueryParameter(filter) {
  if ('queryParameterLocation' in filter && filter.queryParameterLocation === FILTER_QUERY_PARAMETER_LOCATION_VALUE) {
    return filter.options[filter.selectedValue]
  }
  return filter.selectedValue
}

export function jsonOrDefault(response, defaultValue) {
  if (response.status === 200) {
    return response.json();
  }
  return defaultValue;
}

export function noOpPromise(resolutionData) {
  return new Promise((resolve, reject) => {
    resolve(resolutionData)
  })
}