import React from 'react';

import './SearchResult.scss'
import './App.scss'
import GlobalContext from './GlobalContext';
import { API_HOST } from '../react-web-constants';
import { base64Encode, getFullPlayerName, getPlayerId, getPlayerName, getTeamDisplayName, hasPremiumAccess } from '../linemate-react-common/src/util';
import { getTeamLogoPath, getTheme } from '../react-web-utils';
import Trend from './Trend';
import { BET_TYPE_FILTER } from '../linemate-react-common/src/constants';
import SegmentedControl from './SegmentedControl';
import Button from './Button';

function getInjuryItemsPerPage() {
    if (window.screen.width >= 1440) {
        return 6;
    }
    if (window.screen.width >= 1024) {
        return 4;
    }
    if (window.screen.width >= 768) {
        return 4;
    }
    return 2;
}

/**
 * Props:
 * - onBack:fn
 */
class SearchResult extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.textInputChangeHandler = this.textInputChangeHandler.bind(this);
        this.search = this.search.bind(this);
        this.getTrendSection = this.getTrendSection.bind(this);
        this.getSuggestionSection = this.getSuggestionSection.bind(this);

        this.segmentOptions = {
            'profile': 'Profile',
            'injury': 'Injury Impact'
        }

        this.state = {
            searchTerm: null,
            typingTimeout: 0,
            searchResults: [],
            mobileEntrySelected: false,
            injuries: [],
            injuriesPage: 0,
            injuryItemsPerPage: getInjuryItemsPerPage(),
            trendPreviews: {
                trends: [],
                markets: [],
                positions: []
            },
            loaded: false,
            segmentSelection: Object.keys(this.segmentOptions)[0]
        }
    }

    componentDidMount() {
        Promise.all([
            fetch(`${API_HOST}/api/${this.context.league}/v1/discovery/cards/preview?premium=${hasPremiumAccess(this.context.userAttributes)}`).then((data) => data.json()),
            fetch(`${API_HOST}/api/${this.context.league}/v1/teams/injuries`).then((data) => data.json())
        ])
        .then((result) => this.setState({
            trendPreviews: result[0],
            injuries: Object.values(result[1]).flat(1),
            loaded: true
        }))
        .catch(console.log)

        if (this.props.searchTerm) {
            this.search(this.props.searchTerm)
        }

        // Auto-focus the input when the search opens
        document.getElementsByTagName('input')[0].focus();

        this.screenResizeObserver = new ResizeObserver((entries) => {
            const injuryItemsPerPage = getInjuryItemsPerPage()
            if (this.state.injuryItemsPerPage !== injuryItemsPerPage) {
                this.setState({injuryItemsPerPage: injuryItemsPerPage})
            }
        })
        this.screenResizeObserver.observe(document.getElementsByClassName("search-result-container")[0]);
    }

    textInputChangeHandler(e) {
        const searchTerm = e.target.value;
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        this.setState({searchTerm: searchTerm, typingTimeout: setTimeout(() => this.search(searchTerm), 250)})
    }

    search(inputSearchTerm) {
        // TODO: on app inputSearchTerm is used but should be searchTerm
        if (inputSearchTerm != null && inputSearchTerm.trim().length > 1) { 
            const searchTerm = inputSearchTerm.trim().toLowerCase();

            const playerSearchPromise = new Promise((resolve, reject) => {
                fetch(`${API_HOST}/api/${this.context.league}/v1/players/search?searchTerm=${searchTerm}`)
                .then(result => result.json())
                .then(result => resolve(result.map(x => ({type: "player", player: x, teamCode: x.teamCode, text: getFullPlayerName(x), activeGameInfo: x.activeGameInfo}))))
            })

            const teamSearchPromise = new Promise((resolve, reject) => {
                fetch(`${API_HOST}/api/${this.context.league}/v1/teams/search?searchTerm=${searchTerm}`)
                .then(result => result.json())
                .then(result => resolve(result.map(x => ({type: "team", teamCode: x.code, text: getTeamDisplayName(this.context.league, x), activeGameInfo: x.activeGameInfo}))))
            })

            Promise.all([playerSearchPromise, teamSearchPromise])
            .then((results) => {
                const overallPlayerResults = results[0];
                const overallTeamResults = results[1];
                
                this.setState({
                    searchResults: overallPlayerResults.concat(overallTeamResults)
                })
            })
        } else {
            this.setState({
                searchResults: []
            })
        }
    }

    // TODO: update classes
    // The filtersToExclude is not ideal since it assumes the same filter for the whole section.
    // For the trending plays with safe/risky/recent/h2h some should have no filters to exclude and some should have the trends
    //  but to simplify the implementation right now we won't exlude anything for those
    getTrendSection(title, trendPreviewKey, filtersToExclude) {
        if (this.state.trendPreviews[trendPreviewKey].length === 0) {
            return <></>
        }
        return (
            <>
                <div className='home-page-discover-title'>
                    <p className='text-style-h-2-semibold home-page-discover-title-desktop'>{title}</p>
                    <p className='text-style-h-3-semibold home-page-discover-title-mobile'>{title}</p>
                </div>
                <div className='home-page-discover-trends-wrapper'>
                {
                    this.state.trendPreviews[trendPreviewKey].map((trend) => 
                        <Trend loaded={this.state.loaded} title={trend.title} subtitle={trend.subtitle} cards={trend.cards} endpoint={trend.endpoint} type={trend.type} filtersToExclude={filtersToExclude}/>
                    )
                }
                </div>
            </>
        )
    }

    getSuggestionSection() {
        return (
            <>
                {
                    this.state.injuries.length > 0 && (
                        <div className='search-injury-suggestions-section'>
                            <div>
                                <p className='text-style-h-2-semibold color-fig-default'>Search by Injury</p>
                                <div className='search-injury-suggestions-linemate-plus unselectable'>
                                    <p className='text-style-caption-semibold'>Linemate+</p>
                                </div>
                            </div>
                            <div className='search-injury-suggestions-subtitle'>
                                <p className='text-style-h-3-medium color-fig-default'>View how a player's injury impacts trends</p>
                                <div className='search-injury-suggestions-buttons-group'>
                                    <div>
                                        <Button icon='assets/chevron-left.svg' type='secondary' enabled={this.state.injuriesPage > 0} onClick={() => this.setState({injuriesPage: this.state.injuriesPage - 1})}/>
                                    </div>
                                    <div>
                                        <Button icon='assets/chevron-right.svg' type='secondary' enabled={((this.state.injuriesPage + 1) * this.state.injuryItemsPerPage) < this.state.injuries.length} onClick={() => this.setState({injuriesPage: this.state.injuriesPage + 1})}/>
                                    </div>
                                </div>
                            </div>
                            <div className='search-injury-suggestions-wrapper'>
                            {
                                this.state.injuries.slice(this.state.injuriesPage * this.state.injuryItemsPerPage, (this.state.injuriesPage + 1) * this.state.injuryItemsPerPage).map((injury, index) => {
                                    const feed = base64Encode(JSON.stringify({
                                        title: 'Trends',
                                        subtitle: `without ${getPlayerName(injury)}`, 
                                        endpoint: `discovery/injuries?playerId=${getPlayerId(injury)}&teamCode=${injury.teamCode}`, 
                                        filtersToExclude: [], 
                                        includeFilters: false,
                                        returnUrl: `${window.location.pathname}?search`,
                                        without: {
                                            id: getPlayerId(injury),
                                            name: getPlayerName(injury)
                                        }
                                    }))
                                    const url = hasPremiumAccess(this.context.userAttributes) ? `/${this.context.league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['player']))}` : `/pricing?origin=${window.location.pathname}`
                                    return (
                                        <a key={`${injury.teamCode}-${getPlayerId(injury)}`} 
                                                className='search-injury-suggestion unselectable'
                                                href={url}
                                        >
                                            <div>
                                                <img src={getTeamLogoPath(this.context.league, injury.teamCode)} alt={injury.teamCode}/>
                                                <p className='text-style-label-semibold color-fig-default'>{getPlayerName(injury)}</p>
                                            </div>
                                            <p className='text-style-caption-uppercase-semibold color-fig-subtle'>{injury.status}</p>
                                        </a>
                                    )
                                })
                            }
                            </div>
                        </div>
                    )
                }
                {/* Trends by market/prop */}
                {this.getTrendSection("Search by Prop", 'markets', [BET_TYPE_FILTER])}
                {/* Trends by position */}
                {this.getTrendSection("Search by Position", 'positions')}
                {/* Lazy way of adding a margin */}
                <div style={{marginBottom: 32}}></div>
            </>
        )
    }

    render() {
        return (
        <div className='search-result-container'>
            <div className={`search-result-results-list ${this.state.mobileEntrySelected ? "" : "search-result-results-list-mobile-active"}`}>
                <div className='search-result-results-list-header'>
                    <img src="assets/arrow-back.svg" alt="back" onClick={this.props.onBack}/>
                    <div className='search-result-results-list-header-input-wrapper'>
                        <img src='assets/search.svg' alt="search"/>
                        <input defaultValue={this.props.searchTerm} className='text-style-label-normal' onInput={this.textInputChangeHandler} placeholder={'Search any player or team...'}/>
                    </div>
                </div>
                {
                    this.state.searchResults.length > 0 && (
                        <div className='search-result-results-segment-control-wrapper'>
                            <SegmentedControl options={this.segmentOptions} selection={this.state.segmentSelection} selectionHandler={(selection) => this.setState({segmentSelection: selection})} fillWidth={true}/>
                        </div>
                    )
                }
                <div className='search-result-results-list-content'>
                {
                    (!this.state.searchTerm || this.state.searchTerm === "") && this.state.searchResults.length === 0 && (
                        <div className='search-result-results-list-suggestions'>{this.getSuggestionSection()}</div>
                    )
                }
                {
                    (this.state.segmentSelection === "profile" || hasPremiumAccess(this.context.userAttributes)) && (
                        this.state.searchResults.map((result, index) => {
                            const sideText = result.type === "player" ? `⋅ ${result.teamCode} ⋅ ${result.player.info.position}` : "";
                            if (result.type === "team" && this.state.segmentSelection == "injury") {
                                return (<></>)
                            }
                            return (
                                <div key={result.text} 
                                    className='search-result-entry' 
                                    onClick={() => {
                                        if (this.state.segmentSelection === "profile") {
                                            // Here the player id might be enough instead of the whole player info object, tbd
                                            const feed = base64Encode(JSON.stringify({
                                                title: 'Profile', 
                                                subtitle: result.type === "player" ? getPlayerName(result.player.info) : result.teamCode,
                                                player: result.type === "player" ? result.player.info : null,
                                                teamCode: result.teamCode,
                                                returnUrl: `${window.location.pathname}?search=${this.state.searchTerm}`
                                            }))
                                            window.location.href = `/${this.context.league}/trends?feed=${feed}&profile=true`
                                        } else {
                                            const feed = base64Encode(JSON.stringify({
                                                title: `Without ${getPlayerName(result.player)}`, 
                                                endpoint: `discovery/injuries?playerId=${getPlayerId(result.player.info)}&teamCode=${result.teamCode}`, 
                                                filtersToExclude: [], 
                                                includeFilters: false,
                                                returnUrl: `${window.location.pathname}?search=${this.state.searchTerm}`
                                            }))
                                            window.location.href = `/${this.context.league}/trends?feed=${feed}&tabs=${encodeURIComponent(JSON.stringify(['player']))}`
                                        }
                                    }} 
                                    data-last={index === this.state.searchResults.length - 1}
                                >
                                    <img src={getTeamLogoPath(this.context.league, result.teamCode)} alt={result.teamCode}/>
                                    <p className='text-style-body-medium color-fig-default'>{result.text}</p>
                                    <p className='text-style-uppercase-normal color-fig-subtle'>&nbsp;{sideText}</p>
                                </div>
                            )
                        }) 
                    )
                }
                {
                    this.state.searchTerm && this.state.searchTerm !== "" && this.state.segmentSelection === "injury" && !hasPremiumAccess(this.context.userAttributes) && (
                        <div className='search-result-results-injury-showcase'>
                            <img src={`assets/${getTheme()}-injury-showcase.png`} alt="injury-showcase"/>
                            <div className='search-result-results-injury-showcase-text'>
                                <p className='text-style-h-4-semibold color-fig-default'>Unlock Injury Impact with Linemate+</p>
                                <p className='text-style-tabular-normal color-fig-subtle'>Find trends for a player's team and teammates when they are out of the lineup.</p>
                            </div>
                            <div className='search-result-results-injury-showcase-button'>
                                <Button text="Get Linemate+" typography="lg" type="primary" enabled={true} onClick={() =>  window.location.href = `/pricing?origin=${window.location.pathname}`}/>
                            </div>
                        </div>
                    )
                }
                </div>
            </div>
        </div>
        );
    }
}

export default SearchResult;